import React, {useEffect, useState} from 'react';
import {Button, Col, Form, Nav} from "react-bootstrap";
import {Link, useLocation} from "react-router-dom";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faMagnifyingGlass, faPlus} from "@fortawesome/free-solid-svg-icons";
import CreateSpace from "./create-space";
import axios from "axios";
import {setSpaceNames} from "../../store/app/reducers";
import {useDispatch} from "react-redux";
import {SPACES_URL} from "../../utilities/urls";
import {verifyUserProfile} from "../../utilities/util";

const spaceNamesURL = SPACES_URL + "/spaces";

const SpacesNav = ({setSpace, spaceNames, userProfile, showAuth, setAuthScreen, accessToken}) => {
    const {hash} = useLocation();
    const dispatch = useDispatch();

    const [showSpaces, setShowSpaces] = useState(true);
    const [filteredSpaces, setFilteredSpaces] = useState(spaceNames);
    const [searchTerm, setSearchTerm] = useState("");
    const [showCreateSpace, setShowCreateSpace] = useState(false);
    const [createSpaceKey, setCreateSpaceKey] = useState(10 + Date.now());

    useEffect(() => {
        if (hash) {
            const spaceId = hash.replace('#', '');
            setSpace(spaceNames.find(a => a.id === spaceId));
            setShowSpaces(false);
        } else {
            setShowSpaces(true);
        }
        setFilteredSpaces(spaceNames);
        setSearchTerm("");
    }, [hash, spaceNames]);

    useEffect(() => {
        const timeOutId = setTimeout(() => searchSpaceNames(), 500);
        return () => clearTimeout(timeOutId);
    }, [searchTerm]);

    const searchSpaceNames = (e) => {
        if (e && e.preventDefault) {
            e.preventDefault();
        }

        const filteredByDisplayName = spaceNames.filter(spaceName =>
            spaceName.name.toLowerCase().includes(searchTerm.toLowerCase())
        );
        const filteredByName = spaceNames.filter(spaceName =>
            spaceName.name.toLowerCase().includes(searchTerm.toLowerCase()) &&
            !filteredByDisplayName.find(byDisplayName => byDisplayName.name === spaceName.name)
        );
        const filtered = filteredByDisplayName.concat(filteredByName);

        setFilteredSpaces(filtered);
    }

    const onSpaceClick = (space) => {
        setSpace(space);
        setShowSpaces(false);
    }

    const handleCreateClose = () => {
        setShowCreateSpace(false);
        fetchSpaceNames();
    }

    const fetchSpaceNames = () => {
        axios.get(spaceNamesURL).then((response) => {
            dispatch(setSpaceNames(response.data));
        });
    }

    const showCreateModal = () => {
        setShowCreateSpace(true);
        setCreateSpaceKey(10 + Date.now());
    }

    const handleCreate = () => {
        verifyUserProfile(userProfile, showAuth, setAuthScreen, showCreateModal);
    }

    return <Col lg="4" className={`chat-data-left h-100 p-3${showSpaces ? ' show' : ''}`}>
        <CreateSpace key={createSpaceKey} show={showCreateSpace} handleClose={handleCreateClose}
                     accessToken={accessToken}/>
        <div className="chat-search">
            <Form onSubmit={searchSpaceNames}>
                <div className="d-flex position-relative">
                    <Form.Control type="text"
                                  style={{paddingRight: 3 + "rem"}}
                                  placeholder="Search"
                                  value={searchTerm}
                                  onChange={(e) => setSearchTerm(e.target.value)}/>
                    <Button to="#" type="submit" name="send"
                            style={{background: "none", border: "none"}}
                            className="paperplane-submit feather-icon">
                        <FontAwesomeIcon icon={faMagnifyingGlass}/>
                    </Button>
                </div>
            </Form>
        </div>
        <div className="chat-sidebar-channel scroller mt-2">
            <h5 className="mt-2 d-flex justify-content-between">
                Spaces
                <Link to="#" onClick={handleCreate}>
                    <FontAwesomeIcon icon={faPlus} className="create-icon"
                                     style={{fontSize: "1.7rem", marginRight: "1rem"}}/>
                </Link>
            </h5>
            <Nav variant="pills" className="iq-chat-ui nav flex-column">
                {
                    filteredSpaces?.map(space => {
                        return (
                            <Nav.Item as="li" key={space.id}>
                                <Link
                                    className={`${hash === '#' + space.id ? 'active' : ''} nav-link`}
                                    aria-current="page"
                                    onClick={() => onSpaceClick(space)}
                                    to={`/spaces/#${space.id}`}>
                                    <div
                                        className="item-name ps-2">{space.name}</div>
                                </Link>
                            </Nav.Item>
                        );
                    })
                }
            </Nav>
        </div>
    </Col>;
};

export default SpacesNav;