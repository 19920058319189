import React from 'react';
import {Button, Modal} from "react-bootstrap";
import axios from "axios";
import {toast} from "react-toastify";
import {POST_URL} from "../../utilities/urls";
import {useNavigate} from "react-router-dom";

const DeletePost = ({show, handleClose, postId, callback}) => {
    const navigate = useNavigate();

    const handleDeletePost = () => {
        const options = {
            method: 'DELETE',
            url: POST_URL + `/v1/post/${postId}`
        };

        axios.request(options).then(function (response) {
            toast.success("Deleted the post", {
                position: "bottom-right",
                autoClose: 5000,
                closeOnClick: true,
                pauseOnHover: true
            });

            if (callback) {
                callback();
            } else {
                navigate('/');
            }

            handleClose();
        }).catch(function (error) {
            console.error(error);
        });
    }

    return (<Modal centered show={show} onHide={handleClose} backdrop="static">
        <Modal.Header closeButton>
            <Modal.Title>Delete Post</Modal.Title>
        </Modal.Header>
        <Modal.Body>
            <p>Are you sure you want to delete this post?</p>

            <Modal.Footer>
                <Button variant="secondary" onClick={handleClose}>
                    Cancel
                </Button>
                <Button variant="primary" onClick={handleDeletePost}>
                    Delete
                </Button>
            </Modal.Footer>
        </Modal.Body>
    </Modal>);
}

export default DeletePost;