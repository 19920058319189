import React, {createRef, useEffect, useState} from 'react';
import {Button, FloatingLabel, Form, Modal, Spinner} from "react-bootstrap";
import axios from "axios";
import {USER_URL} from "../../utilities/urls";
import {useNavigate} from "react-router-dom";
import {setUserProfile} from "../../store/app/reducers";
import {useDispatch} from "react-redux";

const DeleteAccount = ({show, handleClose, userProfile}) => {
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const [showSpinner, setShowSpinner] = useState(false);
    const [askVerificationCode, setAskVerificationCode] = useState(false);

    const [deleteAccountCurrentIndex, setDeleteAccountCurrentIndex] = useState(0);

    const [deleteAccountInputRefsArray] = useState(() =>
        Array.from({length: 6}, () => createRef())
    );

    const [deleteAccountLetters, setDeleteAccountLetters] = useState(() =>
        Array.from({length: 6}, () => "")
    );

    const handleKeyPress = (e) => {
        if (e?.target?.name?.includes("deleteCode")) {
            setDeleteAccountCurrentIndex((prevIndex) => {
                if (!/\b\d\b/gm.test(e.key)) {
                    return prevIndex;
                }

                if (prevIndex < 5) {
                    const nextIndex = prevIndex + 1;
                    const nextInput = deleteAccountInputRefsArray?.[nextIndex]?.current;
                    nextInput.focus();
                    nextInput.select();

                    return nextIndex;
                } else {
                    return prevIndex;
                }
            });
        }
    };

    useEffect(() => {
        // focus the first input initially
        if (deleteAccountInputRefsArray?.[0]?.current) {
            deleteAccountInputRefsArray?.[0]?.current?.focus();
        }

        // add the event listener for keyup keyboard event
        window.addEventListener("keyup", handleKeyPress, false);

        // remove the event listener when the component unmounts
        return () => {
            window.removeEventListener("keyup", handleKeyPress);
        };
    }, []);

    const onFormSubmit = e => {
        setShowSpinner(true);
        e.preventDefault();
        const formData = new FormData(e.target);

        const options = {
            method: 'DELETE',
            url: USER_URL + `/v1/account`,
            data: {
                verificationCode: deleteAccountLetters.reduce((accumulator, currentValue) => accumulator + currentValue, ""),
                deleteComments: formData.get("comments"),
                deletePosts: formData.get("posts")
            }
        };

        axios.request(options).then(function (response) {
            setShowSpinner(false);
            handleClose();

            handleSignOut();
            navigate("/");
        }).catch(function (error) {
            setShowSpinner(false);
            console.error(error);
        });
    }

    const handleSignOut = () => {
        dispatch(setUserProfile(null));

        const options = {
            method: 'POST',
            url: USER_URL + '/v1/account/signout',
        };

        axios.request(options).then(function (response) {

        }).catch(function (error) {
            console.error(error);
        });
    };

    const sendDeleteEmail = e => {
        setShowSpinner(true);
        e.preventDefault();

        const options = {
            method: 'PATCH',
            url: USER_URL + `/v1/account/delete/email`,
            data: {
                userId: userProfile.userId
            }
        };

        axios.request(options).then(function (response) {
            setShowSpinner(false);
            setAskVerificationCode(true);
        }).catch(function (error) {
            setShowSpinner(false);
            console.error(error);
        });
    }

    return (
        <Modal centered show={show} onHide={handleClose} backdrop="static">
            <Modal.Header closeButton>
                <Modal.Title>Do you really want to delete your account?</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                {
                    askVerificationCode ?
                        <Form onSubmit={onFormSubmit} className="mx-3">
                            <Form.Group className="form-group">
                                <Form.Label>Please enter the 6-digit verification code we sent to your email. This
                                    code expires in 15 minutes.</Form.Label>
                                <div className="d-flex justify-content-center">
                                    {deleteAccountInputRefsArray.map((ref, index) => {
                                        return (
                                            <Form.Control ref={ref}
                                                          key={index}
                                                          type="text"
                                                          pattern="[0-9]"
                                                          inputMode="numeric"
                                                          className="mb-0 email-code"
                                                          name={`deleteCode${index}`}
                                                          required={true}
                                                          onChange={(e) => {
                                                              const {value} = e.target;
                                                              if (/\b\d\b/gm.test(value)) {
                                                                  setDeleteAccountLetters((deleteAccountLetters) =>
                                                                      deleteAccountLetters.map((letter, letterIndex) =>
                                                                          letterIndex === index ? value : letter
                                                                      )
                                                                  );
                                                              } else if (value === "") {
                                                                  setDeleteAccountLetters((deleteAccountLetters) =>
                                                                      deleteAccountLetters.map((letter, letterIndex) =>
                                                                          letterIndex === index ? "" : letter
                                                                      )
                                                                  );
                                                              }
                                                          }}
                                                          onClick={(e) => {
                                                              setDeleteAccountCurrentIndex(index);
                                                              e.target.select();
                                                          }}
                                                          value={deleteAccountLetters[index]}/>
                                        );
                                    })}
                                </div>
                            </Form.Group>

                            <Modal.Footer>
                                <Button variant="secondary" onClick={handleClose}>
                                    Cancel
                                </Button>
                                {
                                    showSpinner ?
                                        <Spinner/> :
                                        <Button variant="primary" type="submit">
                                            Delete
                                        </Button>
                                }
                            </Modal.Footer>
                        </Form> :
                        <Form onSubmit={sendDeleteEmail} className="mx-3">
                            <div>Warning: This action is permanent.</div>
                            <Form.Group className="mb-3 mt-2" controlId="reason">
                                <FloatingLabel label="Reason (Optional)">
                                    <Form.Control name="reason"
                                                  as="textarea"
                                                  style={{height: '100px'}}
                                    />
                                </FloatingLabel>
                                <Form.Check className="d-flex mt-3">
                                    <Form.Check.Input type="checkbox" className="me-2" name="posts"/>
                                    <div>Delete Posts?</div>
                                </Form.Check>
                                <Form.Check className="d-flex mt-2">
                                    <Form.Check.Input type="checkbox" className="me-2" name="comments"/>
                                    <div>Delete Comments?</div>
                                </Form.Check>
                            </Form.Group>

                            <Modal.Footer>

                                {
                                    showSpinner ?
                                        <Spinner className="me-5" style={{marginTop: "0.9rem"}}/> :
                                        <>
                                            <Button variant="secondary" onClick={handleClose}>
                                                Cancel
                                            </Button>
                                            <Button variant="primary" type="submit">
                                                Continue
                                            </Button>
                                        </>
                                }
                            </Modal.Footer>
                        </Form>
                }
            </Modal.Body>
        </Modal>
    );
}

export default DeleteAccount;