import React, {useState} from 'react';
import {Link} from "react-router-dom";
import axios from "axios";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faHeart} from "@fortawesome/free-regular-svg-icons";
import CreateComment from "./create-comment";
import {COMMENTS_URL} from "../../utilities/urls";
import {faReply} from "@fortawesome/free-solid-svg-icons";
import {defaultAvatar, getAge, verifyUserProfile} from "../../utilities/util";

const Comment = ({comment, showAuth, setAuthScreen, closeSignUp, userProfile}) => {

    const [likes, setLikes] = useState(comment?.likes);
    const [showReply, setShowReply] = useState(false);
    const [showReplies, setShowReplies] = useState(false);
    const [replies, setReplies] = useState(null);
    const [createdReplies, setCreatedReplies] = useState([]);

    const handleUpVote = (commentId) => {
        verifyUserProfile(userProfile, showAuth, setAuthScreen, () => upVote(commentId));
    }

    const upVote = (commentId) => {
        const options = {
            method: 'PATCH',
            url: COMMENTS_URL + `/v1/comment/${commentId}/like`
        };

        axios.request(options).then(function (response) {
            setLikes(likes + 1);
        }).catch(function (error) {
            console.error(error);
        });
    }

    const handleReply = () => {
        verifyUserProfile(userProfile, showAuth, setAuthScreen, () => setShowReply(true));
    };

    const getReplies = () => {
        setShowReply(false);
        const options = {
            method: 'GET',
            url: COMMENTS_URL + '/v1/comment',
            params: {
                commentId: comment.commentId,
                postId: comment.postId
            }
        };

        axios.request(options).then(function (response) {
            setReplies(response.data.data.comments[0].replies);
            setShowReplies(true);
            document.getElementById(comment.commentId).scrollIntoView({
                behavior: "smooth",
                block: "center",
                inline: "center"
            });
        }).catch(function (error) {
            console.error(error);
        });
    };

    const addToComments = (commentFromCreate) => {
        setShowReply(false);
        comment.replyCount = (comment.replyCount || 0) + 1;
        createdReplies.unshift(commentFromCreate);
        setCreatedReplies(createdReplies);
    }

    const handleReplies = () => {
        setCreatedReplies([]);
        getReplies(1);
    }

    return (
        <li className="mb-2">
            <div className="d-flex ">
                <div className="user-img">
                    <img src={comment.creator.avatarUrl || defaultAvatar} alt="avatar2"
                         className="avatar-35 rounded-circle img-fluid"/>
                </div>
                <div className="comment-data-block ms-3 w-100">
                    <div className="d-flex justify-content-between">
                        <div className="d-flex justify-content-between mt-1">
                            <p className="mb-0 d-inline-block"
                               style={{fontSize: '0.8rem'}}>{comment.creator.displayName}</p>
                            <p className="mb-0 ms-1"
                               style={{fontSize: '0.8rem'}}>&nbsp;{getAge(comment.createdDate)}</p>
                        </div>
                        {/* <div className="card-post-toolbar">
                            <Dropdown>
                                <Dropdown.Toggle variant="bg-transparent" style={{color: "white"}}>
                                    <FontAwesomeIcon icon={faEllipsis} style={{fontSize: 1.1 + "rem"}}/>
                                </Dropdown.Toggle>
                                <Dropdown.Menu className="dropdown-menu m-0 p-0">
                                    <Dropdown.Item className="p-3" to="#">
                                        <div className="d-flex align-items-top">
                                            <div className="data ms-2">
                                                <p className="mb-0">Report</p>
                                            </div>
                                        </div>
                                    </Dropdown.Item>
                                </Dropdown.Menu>
                            </Dropdown>
                        </div>*/}
                    </div>
                    <p className="mb-0">{comment.content}</p>
                    {comment.media &&
                        <img src={comment.media.url} alt="missing image" className="img-fluid mb-1 comments-image"/>
                    }
                    <div className="d-flex flex-wrap align-items-center comment-activity mt-1">
                        <div className="like-block position-relative d-flex align-items-center">
                            <Link to="#" onClick={() => handleUpVote(comment.commentId)}
                                  className="d-flex align-items-center feather-icon">
                                {likes && likes > 0 ?
                                    <div className="me-2" style={{fontSize: "13px"}}>{likes}</div> : <></>}
                                <FontAwesomeIcon icon={faHeart} style={{fontSize: 1.3 + "rem"}}/>
                            </Link>
                            <Link to="#" className="d-flex align-items-center ms-4 feather-icon"
                                  onClick={() => handleReply()}>
                                <FontAwesomeIcon icon={faReply} style={{fontSize: 1.3 + "rem"}}/>
                            </Link>
                        </div>
                    </div>
                    {
                        (!showReplies && comment.replyCount !== null && comment.replyCount > 0 && createdReplies?.length === 0) &&
                        <div>
                            <Link to="#" onClick={handleReplies} className="reply">
                                View {comment.replyCount} {comment.replyCount === 1 ? "reply" : "replies"}
                            </Link>
                        </div>
                    }
                    {
                        showReply &&
                        <CreateComment postId={comment.postId} commentId={comment.commentId} showAuth={showAuth}
                                       setAuthScreen={setAuthScreen} userProfile={userProfile}
                                       addToComments={addToComments}/>
                    }
                    <ul className="post-comments list-inline p-0 m-0 mt-1" id={comment.commentId}>
                        {
                            createdReplies.map(comment => <Comment comment={comment} key={comment.commentId}
                                                                   showAuth={showAuth}
                                                                   setAuthScreen={setAuthScreen}
                                                                   closeSignUp={closeSignUp}
                                                                   userProfile={userProfile}/>)
                        }
                        {
                            (showReplies && replies) &&
                            replies.map(comment => <Comment comment={comment}
                                                            key={comment.commentId}
                                                            showAuth={showAuth}
                                                            setAuthScreen={setAuthScreen}
                                                            closeSignUp={closeSignUp}
                                                            userProfile={userProfile}/>)

                        }
                    </ul>
                </div>
            </div>
        </li>
    );
};

Comment.propTypes = {};

export default Comment;