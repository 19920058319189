import React, {useRef, useState} from 'react';
import {Button, Form, ProgressBar, Spinner} from "react-bootstrap";
import {MEDIA_URL, USER_URL} from "../../utilities/urls";
import axios from "axios";
import {setPreferredLanguage, setUserProfile} from "../../store/app/reducers";
import {useDispatch, useSelector} from "react-redux";

const Preferences = ({handleClose, authCallBack}) => {
    const dispatch = useDispatch();

    const languages = useSelector(state => state.languages);
    const states = useSelector(state => state.states);

    const languageRef = useRef();

    const [showImage, setShowImage] = useState(false);
    const [showProgressbar, setShowProgressbar] = useState(false);
    const [uploadResponse, setUploadResponse] = useState(null);
    const [uploadProgress, setUploadProgress] = useState(0);
    const [showSpinner, setShowSpinner] = useState(false);

    const handleFileUpload = (event) => {
        const file = event.target.files[0]
        const filename = file.name
        const contentType = file.type
        const fileType = 'avatar'

        const options = {
            method: 'POST',
            url: MEDIA_URL + '/v1/media/upload',
            headers: {
                'Content-Type': contentType,
                'File-Name': filename,
                'File-Type': fileType
            },
            data: file, onUploadProgress: progressEvent => {
                const uploadProgress = (progressEvent.loaded / progressEvent.total) * 100;
                setUploadProgress(uploadProgress);
            }
        };

        setShowProgressbar(true);
        axios.request(options).then(function (response) {
            setUploadResponse(response.data.data);
            setShowImage(true);
            setShowProgressbar(false);
        }).catch(function (error) {
            setShowProgressbar(false);
            console.error(error);
        });
    };

    const onPreferencesSubmit = e => {
        setShowSpinner(true);
        e.preventDefault();
        const formData = new FormData(e.target);

        const options = {
            method: 'PATCH',
            url: USER_URL + '/v1/user',
            data: {
                preferredLanguage: formData.get("language"),
                state: formData.get("state"),
                ...(uploadResponse && {avatarPath: uploadResponse.path})
            }
        };

        axios.request(options).then(function (response) {
            setShowSpinner(false);
            dispatch(setUserProfile(response.data.data));
            response.data.data.preferredLanguage && dispatch(setPreferredLanguage(response.data.data.preferredLanguage));
            handleClose();
            authCallBack && authCallBack();
        }).catch(function (error) {
            setShowSpinner(false);
            console.error(error);
        });
    }

    return (
        <div className="sign-in-from">
            <Form onSubmit={onPreferencesSubmit}>
                <Form.Group className="mb-3" controlId="language">
                    <Form.Label>Preferred Language (for post translations)</Form.Label>
                    <Form.Select name="language" required={true} ref={languageRef}>
                        <option disabled selected value="">Select a Language</option>
                        {languages.map(language => (
                            <option key={language.code}
                                    value={language.code}>{language.name}</option>))}
                    </Form.Select>
                </Form.Group>
                <Form.Group className="mb-3" controlId="state">
                    <Form.Label>State (Select your region to see relevant posts and tag your own)</Form.Label>
                    <Form.Select name="state" required={true} ref={languageRef}>
                        <option disabled selected value="">Select your state</option>
                        {states.map(state => (
                            <option key={state.id}
                                    value={state.id}>{state.name}</option>))}
                    </Form.Select>
                </Form.Group>
                <div className="justify-content-start mb-3">
                    {showImage ?
                        <>
                            <img src={uploadResponse.url} alt="Upload complete"
                                 className="img-fluid rounded-circle" style={{width: "6rem", height: "6rem"}}/>
                            <div>
                                <input hidden id="avatar-input" type="file"
                                       accept="image/*"
                                       onInputCapture={(event) => handleFileUpload(event)}/>
                                <label htmlFor="avatar-input" className="pointer">
                                    <div className="create-options">Change here</div>
                                </label>
                            </div>
                        </> :
                        <>
                            {showProgressbar &&
                                <ProgressBar animated striped now={uploadProgress}></ProgressBar>}
                            <Form.Label className="custom-file-input">Avatar (optional)</Form.Label>
                            <input type="file" className="form-control rounded align-text-bottom"
                                   accept="image/*"
                                   onInputCapture={(event) => handleFileUpload(event)}/>
                        </>
                    }
                </div>
                <div className="d-inline-block w-100">
                    {
                        showSpinner ?
                            <Spinner className="float-end me-3 spinner-margin"/> :
                            <Button type="submit" className="btn-primary float-end">Save</Button>
                    }
                </div>
            </Form>
        </div>
    );
};

export default Preferences;