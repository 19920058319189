import React from 'react'
import {useOutletContext, useParams} from 'react-router-dom'
import Content from "./content";
import axios from "axios";
import {POSTS_URL} from "../../utilities/urls";
import {Helmet} from "react-helmet";

const Tag = () => {
    const params = useParams();
    const tag = params.id;

    const {
        tagPosts,
        setTagPosts,
        tagPage,
        setTagPage,
        showAuth,
        setAuthScreen,
        showReportPost,
        preferredLanguage,
        userProfile,
        showLanguageSelection,
        showDeletePost,
        showEditPost
    } = useOutletContext();

    React.useEffect(() => {
        fetchData(1);
    }, [tag]);

    const fetchData = (page) => {
        const url = POSTS_URL + `/v1/posts?tag=${tag}&page=${page}`

        axios.get(url).then((response) => {
            if (tagPosts == null || page === 1) {
                setTagPosts(response.data.data.posts);
            } else {
                setTagPosts(tagPosts.concat(response.data.data.posts));
            }
            setTagPage(response.data.data.page.nextPage);
        });
    }

    const deleteCallback = () => {
        setTagPosts(null);
        fetchData(1);
    }

    return tagPosts && (
        <>
            <Helmet>
                <title>{tag}</title>
                <meta name="description" content={`${tag}`}/>
                <meta property="og:title" content={`${tag}`}/>
                <meta property="og:description" content={`${tag}`}/>
            </Helmet>
            <Content posts={tagPosts} nextPage={() => fetchData(tagPage)} pageNumber={tagPage}
                     showAuth={showAuth} setAuthScreen={setAuthScreen}
                     showReportPost={showReportPost} deleteCallback={deleteCallback}
                     preferredLanguage={preferredLanguage} userProfile={userProfile}
                     showDeletePost={showDeletePost} showLanguageSelection={showLanguageSelection}
                     showEditPost={showEditPost}/>
        </>
    )
}

export default Tag