import React, {useRef, useState} from 'react';
import Card from "../common/card";
import {Form, ProgressBar, Spinner} from "react-bootstrap";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faImage} from "@fortawesome/free-solid-svg-icons";
import {Link} from "react-router-dom";
import {faPaperPlane} from "@fortawesome/free-regular-svg-icons";
import axios from "axios";
import Tenor from "../common/tenor";
import {COMMENTS_URL, MEDIA_URL} from "../../utilities/urls";
import {toast} from "react-toastify";
import {verifyUserProfile} from "../../utilities/util";

const CreateComment = ({postId, commentId, addToComments, showAuth, setAuthScreen, userProfile}) => {
    const commentRef = useRef();

    const [showGifModal, setShowGifModal] = useState(false);
    const [gifKey, setGifKey] = React.useState(Date.now());

    const [showProgressbar, setShowProgressbar] = useState(false);
    const [showImage, setShowImage] = useState(false);
    const [uploadResponse, setUploadResponse] = useState(null);
    const [uploadProgress, setUploadProgress] = useState(0);

    const [showSpinner, setShowSpinner] = useState(false);

    const [isGif, setIsGif] = useState(false);

    const handleGifModalClose = (gifDetails) => {
        if (gifDetails) {
            setShowImage(true);
            setIsGif(true);
            handleGifUpload(gifDetails);
        }

        setShowGifModal(false);
        setGifKey(Date.now());
    }

    const handleShowGifModal = () => {
        verifyUserProfile(userProfile, showAuth, setAuthScreen, () => setShowGifModal(true));
    }

    const handleDeleteImage = () => {
        setShowImage(false);
        setIsGif(false);
        setUploadResponse(null);
    }

    const handleFileUpload = (event) => {
        verifyUserProfile(userProfile, showAuth, setAuthScreen, () => fileUpload(event));
    };

    const fileUpload = (event) => {
        const file = event.target.files[0]
        const filename = file.name
        const contentType = file.type
        const fileType = 'image'

        const options = {
            method: 'POST',
            url: MEDIA_URL + '/v1/media/upload',
            headers: {
                'Content-Type': contentType,
                'File-Name': filename,
                'File-Type': fileType
            },
            data: file,
            onUploadProgress: progressEvent => {
                const uploadProgress = (progressEvent.loaded / progressEvent.total) * 100;
                setUploadProgress(uploadProgress);
            }
        };

        setShowProgressbar(true);
        axios.request(options).then(function (response) {
            setShowProgressbar(false);
            setUploadResponse(response.data.data);
            setShowImage(true);
        }).catch(function (error) {
            console.error(error);
        });
    }

    const handleGifUpload = (gifDetails) => {
        verifyUserProfile(userProfile, showAuth, setAuthScreen, () => gifUpload(gifDetails));
    };

    const gifUpload = (gifDetails) => {
        const options = {
            method: 'POST',
            url: MEDIA_URL + '/v1/media/thirdparty',
            data: {
                url: gifDetails.url,
                contentLength: gifDetails.size,
                type: "image"
            },
            onUploadProgress: progressEvent => {
                const uploadProgress = (progressEvent.loaded / progressEvent.total) * 100;
                setUploadProgress(uploadProgress);
            }
        };

        setShowProgressbar(true);
        axios.request(options).then(function (response) {
            setShowProgressbar(false);
            setUploadResponse(response.data.data);
            setShowImage(true);
        }).catch(function (error) {
            console.error(error);
        });
    }

    const handleCreateComment = (commentText) => {
        verifyUserProfile(userProfile, showAuth, setAuthScreen, () => createComment(commentText));
    };

    const createComment = (commentText) => {
        if (commentText || uploadResponse) {
            setShowSpinner(true);

            const options = {
                method: 'POST',
                url: COMMENTS_URL + '/v1/comment',
                data: {
                    postId: postId,
                    parentId: commentId ? commentId : postId,
                    parentType: commentId ? 'comment' : 'post',
                    content: commentText.trim(),
                    type: uploadResponse ? 'media' : 'text',
                    source: isGif ? 'tenor' : 'user',
                    ...(uploadResponse && {mediaId: uploadResponse.mediaId})
                }
            };

            axios.request(options).then(function (response) {
                setShowSpinner(false);
                addToComments(response.data.data);
                handleDeleteImage();
                commentRef.current.value = '';
                toast("Your first comment is in—fantastic!", {
                    icon: ({type}) => "💬",
                    position: "bottom-right",
                    autoClose: 5000,
                    closeOnClick: true,
                    pauseOnHover: true
                });
            }).catch(function (error) {
                setShowSpinner(false);
                handleDeleteImage();
                console.error(error);
            });
        }
    }


    return (
        <>
            <Tenor key={gifKey} handleClose={handleGifModalClose} show={showGifModal}/>
            <Card>
                <Card.Body>
                    <Form onSubmit={e => e.preventDefault()}>
                        <div className="d-flex">
                            <Form.Control ref={commentRef} type="text" style={{paddingRight: 3 + "rem"}}
                                          placeholder="Enter Your Comment"/>
                            {
                                showSpinner ?
                                    <Spinner className="comment-send-spinner"/> :
                                    <Link to="#" className="comment-send feather-icon"
                                          onClick={() => handleCreateComment(commentRef.current.value)}>
                                        <FontAwesomeIcon icon={faPaperPlane}/>
                                    </Link>
                            }
                        </div>
                        <div className="d-flex">
                            <>
                                <input hidden id="comment-image-input" required={true} type="file" name="image-file"
                                       accept="image/*"
                                       onInputCapture={(event) => handleFileUpload(event)}/>
                                <label htmlFor="comment-image-input"
                                       className="pointer d-flex create-options feather-icon">
                                    <FontAwesomeIcon icon={faImage}
                                                     style={{fontSize: "1.4rem", marginTop: ".2rem", padding: "5px"}}/>
                                </label>
                            </>
                            <Link to="#" className="feather-icon" onClick={() => handleShowGifModal()}>
                                <div className="material-symbols-outlined"
                                     style={{fontSize: "1.9rem", padding: "5px"}}>gif_2
                                </div>
                            </Link>
                        </div>
                    </Form>
                    {showProgressbar && <ProgressBar animated striped now={uploadProgress}></ProgressBar>}
                    {showImage && uploadResponse &&
                        <div className="modal-header" style={{flexDirection: "column", alignItems: "start"}}>
                            <button type="button" className="btn-close" style={{paddingLeft: 17 + 'rem'}}
                                    aria-label="Close"
                                    onClick={() => handleDeleteImage()}></button>
                            <img src={uploadResponse.url} alt="Loading"
                                 className="image" style={{width: "130px"}}/>
                        </div>}
                </Card.Body>
            </Card>
        </>
    );
};

export default CreateComment;