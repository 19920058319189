import React, {createRef, useEffect, useState} from 'react';
import {Link} from "react-router-dom";
import {Button, Form, Spinner} from "react-bootstrap";
import {USER_URL} from "../../utilities/urls";
import axios from "axios";

const VerifyEmail = ({emailAddress, showPreferences, sendVerificationCode}) => {
    const [inputRefsArray] = useState(() =>
        Array.from({length: 6}, () => createRef())
    );

    const [currentIndex, setCurrentIndex] = useState(0);
    const [letters, setLetters] = useState(() =>
        Array.from({length: 6}, () => "")
    );

    const [showSpinner, setShowSpinner] = useState(false);
    const [showResendSpinner, setShowResendSpinner] = useState(false);
    const [showModifyEmail, setShowModifyEmail] = useState(false);
    const [showModifyEmailSpinner, setShowModifyEmailSpinner] = useState(false);
    const [email, setEmail] = useState(emailAddress);

    const handleKeyPress = (e) => {
        if (e.target.name && e.target.name[0]) {
            const value = e.key;
            const index = Number(e.target.name[0]);
            if (/\b\d\b/gm.test(value)) {
                setLetters((letters) =>
                    letters.map((letter, letterIndex) =>
                        letterIndex === index ? value : letter
                    )
                );
            } else if (value === "") {
                setLetters((letters) =>
                    letters.map((letter, letterIndex) =>
                        letterIndex === index ? "" : letter
                    )
                );
            }

            if (e?.target?.name?.includes("code")) {
                setCurrentIndex((prevIndex) => {
                    if (!/\b\d\b/gm.test(e.key)) {
                        return prevIndex;
                    }

                    if (prevIndex < 5) {
                        const nextIndex = prevIndex + 1;
                        const nextInput = inputRefsArray?.[nextIndex]?.current;
                        nextInput.focus();
                        nextInput.select();

                        return nextIndex;
                    } else {
                        return prevIndex;
                    }
                });
            }
        }
    };

    useEffect(() => {
        if (sendVerificationCode) {
            resendCode();
        }
        // add the event listener for keyup keyboard event
        window.addEventListener("keyup", handleKeyPress, false);

        // remove the event listener when the component unmounts
        return () => {
            window.removeEventListener("keyup", handleKeyPress);
        };
    }, []);

    const onEmailVerificationFormSubmit = e => {
        setShowSpinner(true);
        e.preventDefault();

        const options = {
            method: 'PATCH',
            url: USER_URL + '/v1/account/email/verify',
            data: {
                emailAddress: emailAddress,
                verificationCode: letters.reduce((accumulator, currentValue) => accumulator + currentValue, "")
            }
        };

        axios.request(options).then(function (response) {
            setShowSpinner(false);
            window.removeEventListener("keyup", handleKeyPress);
            showPreferences();
        }).catch(function (error) {
            setShowSpinner(false);
            window.removeEventListener("keyup", handleKeyPress);
            console.error(error);
        });
    }

    const resendCode = () => {
        setShowResendSpinner(true);
        const options = {
            method: 'POST',
            url: USER_URL + '/v1/account/email/verify/send-email',
            data: {
                emailAddress: emailAddress
            }
        };

        axios.request(options).then(function (response) {
            setShowResendSpinner(false);
        }).catch(function (error) {
            setShowResendSpinner(false);
            console.error(error);
        });
    }

    const updateEmail = () => {
        setShowModifyEmailSpinner(true);
        const options = {
            method: 'PATCH',
            url: USER_URL + '/v1/account/unverified-email',
            data: {
                emailAddress: email
            }
        };

        axios.request(options).then(function (response) {
            setShowModifyEmailSpinner(false);
        }).catch(function (error) {
            setShowModifyEmailSpinner(false);
            console.error(error);
        });
    }

    return (
        <div className="sign-in-from">
            <Form onSubmit={onEmailVerificationFormSubmit}>
                <Form.Group className="form-group">
                    <Form.Control type="email" className="mb-0" name="emailAddress" required={true}
                                  disabled={!showModifyEmail}
                                  onChange={(event) => setEmail(event.target.value)}
                                  value={email}/>
                    {
                        showModifyEmail ?
                            showModifyEmailSpinner ?
                                <Spinner className="me-4 verify-spinner float-end"/> :
                                <div className="float-end">
                                    <Button variant="secondary mt-2 mb-2 mx-2"
                                            onClick={() => setShowModifyEmail(false)}>Cancel</Button>
                                    <Button variant="primary mt-2 mb-2" onClick={updateEmail}>Update</Button>
                                </div>
                            :
                            <Link to="#" onClick={() => setShowModifyEmail(true)}>Modify</Link>
                    }
                </Form.Group>
                {
                    !showModifyEmail &&
                    <>
                        <Form.Group className="form-group">
                            <Form.Label>Please enter the 6-digit verification code sent to your email. This code will
                                expire in 15 minutes.</Form.Label>
                            <div className="d-flex justify-content-center">
                                {inputRefsArray.map((ref, index) => {
                                    return (
                                        <Form.Control ref={ref}
                                                      key={index}
                                                      type="text"
                                                      pattern="[0-9]"
                                                      inputMode="numeric"
                                                      className="mb-0 email-code"
                                                      name={`${index}code`}
                                                      autoFocus={index === 0}
                                                      required={true}
                                                      onClick={(e) => {
                                                          setCurrentIndex(index);
                                                          e.target.select();
                                                      }}
                                                      value={letters[index]}/>
                                    );
                                })}
                            </div>
                        </Form.Group>
                        <div className="d-flex align-items-center justify-content-between w-100 mt-4">
                            {
                                showSpinner ?
                                    <>
                                        <div></div>
                                        <Spinner className="me-4 spinner-margin"/>
                                    </> :
                                    showResendSpinner ?
                                        <Spinner className="ms-4 spinner-margin"/> :
                                        <>
                                            <Link to="#" onClick={resendCode} style={{color: "var(--bs-primary)"}}>
                                                Resend Code?</Link>
                                            <Button variant="primary" type="submit">Submit</Button>
                                        </>
                            }
                        </div>
                    </>
                }
            </Form>

        </div>
    );
};

export default VerifyEmail;