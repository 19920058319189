import React from 'react'
import {Container} from 'react-bootstrap'
import {useDispatch} from "react-redux";
import axios from "axios";
import {Outlet, useLocation, useNavigate, useOutletContext} from "react-router-dom";
import {USER_URL} from "../../utilities/urls";
import {setPreferredLanguage, setUserProfile} from "../../store/app/reducers";
import ProfileNavLinks from "../../components/postup/profile-nav-links";

const UserProfile = () => {
    let navigate = useNavigate();
    const location = useLocation();
    const dispatch = useDispatch();

    const {
        showDeletePost,
        preferredLanguage,
        userProfile,
        showLanguageSelection
    } = useOutletContext();

    React.useEffect(() => {
        getUserProfile();
        if (!userProfile) {
            return navigate('/');
        }
    }, []);

    const getUserProfile = () => {
        const profileUrl = USER_URL + "/v1/user";

        axios.get(profileUrl).then((response) => {
            dispatch(setUserProfile(response.data.data));
            response.data.data.preferredLanguage && dispatch(setPreferredLanguage(response.data.data.preferredLanguage));
        }).catch(function (error) {
            dispatch(setUserProfile(null));
        });
    }

    const contextObj = {
        showDeletePost: showDeletePost,
        preferredLanguage: preferredLanguage,
        userProfile: userProfile,
        showLanguageSelection: showLanguageSelection
    }

    return userProfile && (
        <>
            <div id="content-page" className="content-page">
                <Container>
                    <ProfileNavLinks location={location}/>
                    <Outlet
                        context={contextObj}/>
                </Container>
            </div>
        </>
    )
}

export default UserProfile;