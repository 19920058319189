import React, {useState} from 'react';
import {Button, Form, Spinner} from "react-bootstrap";
import {Link} from "react-router-dom";
import {USER_URL} from "../../utilities/urls";
import axios from "axios";
import {setAccessToken, setPreferredLanguage, setUserProfile} from "../../store/app/reducers";
import {useDispatch} from "react-redux";

const SignIn = ({verifyEmail, signUp, forgotPassword, handleClose, authCallBack}) => {
    const dispatch = useDispatch();

    const [showSpinner, setShowSpinner] = useState(false);

    const onSignInSubmit = e => {
        setShowSpinner(true);
        e.preventDefault();
        const formData = new FormData(e.target);

        const options = {
            method: 'POST',
            url: USER_URL + '/v1/account/signin',
            data: {
                username: formData.get("emailAddress").trim(),
                password: formData.get("password")
            }
        };

        axios.request(options).then(function (response) {
            setShowSpinner(false);
            dispatch(setUserProfile(response.data.data.userProfile));
            dispatch(setAccessToken(response.data.data.accessToken));
            response.data.data.userProfile.preferredLanguage && dispatch(setPreferredLanguage(response.data.data.userProfile.preferredLanguage));
            handleClose();

            if (response.data.data.userProfile.roles.includes("ROLE_UNVERIFIED_USER")) {
                const options = {
                    method: 'PATCH',
                    url: USER_URL + '/v1/account/email/verify/send-email',
                    data: {
                        emailAddress: formData.get("emailAddress")
                    }
                };

                axios.request(options).then(function (response) {
                    verifyEmail(formData.get("emailAddress"));
                }).catch(function (error) {
                    console.error(error);
                });
            } else {
                authCallBack && authCallBack instanceof Function && authCallBack();
            }
        }).catch(function (error) {
            setShowSpinner(false);
            console.log(error);
        });
    }

    return (
        <div className="sign-in-from">
            <Form onSubmit={onSignInSubmit}>
                <Form.Group className="form-group">
                    <Form.Label>Email Address</Form.Label>
                    <Form.Control type="email" className="mb-0" name="emailAddress" autoFocus
                                  placeholder="Enter Email" required={true}/>
                </Form.Group>
                <Form.Group className="form-group">
                    <Form.Label>Password</Form.Label>
                    <Form.Control type="password" className="mb-0" name="password"
                                  placeholder="Enter Password" minLength={8}/>
                    <Link to="#" className="float-end" onClick={() => forgotPassword()}>Forgot password?</Link>
                </Form.Group>
                <div className="d-inline-block w-100 mt-2">
                    {
                        showSpinner ?
                            <Spinner className="float-end me-4 spinner-margin"/> :
                            <Button variant="primary" type="submit" className="float-end">Sign in</Button>
                    }
                </div>
                <div className="sign-info">
                <span className="dark-color d-inline-block line-height-2">Don't have an account?
                   <Link to="#" onClick={() => {
                       signUp();
                   }}> Sign up</Link>
                </span>
                </div>
            </Form>
        </div>
    );
};

export default SignIn;