import React, {useState} from 'react';
import {Button, Form, Spinner} from "react-bootstrap";
import {Link} from "react-router-dom";
import {USER_URL} from "../../utilities/urls";
import axios from "axios";
import {setPreferredLanguage, setUserProfile} from "../../store/app/reducers";
import {useDispatch} from "react-redux";

const SignUp = ({signIn, verifyEmail}) => {
    const dispatch = useDispatch();

    const [showSpinner, setShowSpinner] = useState(false);

    const onSingUpFormSubmit = e => {
        setShowSpinner(true);
        e.preventDefault();
        const formData = new FormData(e.target);

        const options = {
            method: 'POST',
            url: USER_URL + '/v1/account/signup',
            data: {
                displayName: formData.get("displayName").trim(),
                fullName: formData.get("fullName").trim(),
                password: formData.get("password").trim(),
                emailAddress: formData.get("emailAddress").trim()
            }
        };

        axios.request(options).then(function (response) {
            setShowSpinner(false);
            verifyEmail(formData.get("emailAddress").trim());
            dispatch(setUserProfile(response.data.data.userProfile));
            response.data.data.userProfile.preferredLanguage && dispatch(setPreferredLanguage(response.data.data.userProfile.preferredLanguage));
        }).catch(function (error) {
            setShowSpinner(false);
            console.error(error);
        });
    }

    return (
        <div className="sign-in-from">
            <Form onSubmit={onSingUpFormSubmit} autoComplete="off">
                <Form.Group className="form-group">
                    <Form.Label>Full Name</Form.Label>
                    <Form.Control type="text" className="mb-0" name="fullName" autoFocus
                                  placeholder="Your Full Name"
                                  required={true} maxLength={50}/>
                </Form.Group>
                <Form.Group className="form-group">
                    <Form.Label>Display Name</Form.Label>
                    <Form.Control type="text" className="mb-0" name="displayName"
                                  placeholder="A unique display name"
                                  required={true} minLength={3} maxLength={16}/>
                </Form.Group>
                <Form.Group className="form-group">
                    <Form.Label>Email Address</Form.Label>
                    <Form.Control type="email" className="mb-0" name="emailAddress"
                                  placeholder="Enter your email"
                                  required={true}/>
                </Form.Group>
                <Form.Group className="form-group">
                    <Form.Label>Password</Form.Label>
                    <Form.Control type="password" className="mb-0" name="password"
                                  placeholder="Password"
                                  required={true} minLength={8}/>
                </Form.Group>
                <div className="d-inline-block w-100">
                    <Form.Check className="d-inline-block mt-2 pt-1">
                        <Form.Check.Input type="checkbox" name="terms" required={true}/>
                        <Form.Check.Label>I agree to the <Link to="/terms" target="_blank">Terms & Conditions</Link> and
                            the <Link to="/privacy-policy" target="_blank">Privacy Policy</Link>.</Form.Check.Label>
                    </Form.Check>
                    {
                        showSpinner ?
                            <Spinner className="float-end me-4 spinner-margin"/> :
                            <Button type="submit" className="btn-primary float-end">Sign Up</Button>
                    }
                </div>

                <div className="sign-info">
                    <span className="dark-color d-inline-block line-height-2">Already have an account?
                          <Link to="#" onClick={() => signIn()}> Sign In</Link>
                    </span>
                </div>
            </Form>
        </div>
    );
};

SignUp.propTypes = {};

export default SignUp;