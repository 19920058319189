import React from 'react';
import {Col, Container, Row} from "react-bootstrap";
import InfiniteScroll from "react-infinite-scroll-component";
import PostPlaceholder from "../../components/postup/post-placeholder";
import PostPlaceHolder from "../../components/postup/post-placeholder";
import Post from "../../components/postup/post";

const Content = ({
                     posts,
                     nextPage,
                     pageNumber,
                     showAuth,
                     setAuthScreen,
                     showReportPost,
                     preferredLanguage,
                     userProfile,
                     showLanguageSelection,
                     showDeletePost,
                     deleteCallback,
                     showEditPost
                 }) => {
    const showDeletePostWithCallback = (postId) => {
        showDeletePost(postId, deleteCallback);
    }

    return (
        <div className="content-page">
            <Container>
                <Row>
                    <Col lg={12} className="row m-0 p-0">
                        {
                            posts ?
                                <InfiniteScroll
                                    dataLength={posts.length}
                                    next={nextPage}
                                    hasMore={pageNumber != null}
                                    loader={
                                        <PostPlaceholder/>
                                    }
                                    endMessage={
                                        <p style={{textAlign: "center"}}>
                                            <b>Yay! You have seen it all</b>
                                        </p>
                                    }
                                >
                                    {
                                        posts.map((post, index) => {
                                            return (<Col sm={12} key={index}>
                                                <Post post={post} showAuth={showAuth}
                                                      setAuthScreen={setAuthScreen}
                                                      showReportPost={showReportPost}
                                                      preferredLanguage={preferredLanguage}
                                                      userProfile={userProfile}
                                                      showDeletePost={showDeletePostWithCallback}
                                                      showLanguageSelection={showLanguageSelection}
                                                      showEditPost={showEditPost}
                                                />
                                            </Col>)
                                        })
                                    }
                                </InfiniteScroll>
                                :
                                <PostPlaceHolder/>
                        }
                    </Col>
                </Row>
            </Container>
        </div>
    );
};

export default Content;