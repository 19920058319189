import React from 'react'
import Content from "./content";
import {useOutletContext, useSearchParams} from "react-router-dom";
import axios from "axios";
import {POSTS_URL} from "../../utilities/urls";
import {Helmet} from "react-helmet";

const SearchResults = () => {
    const {
        showAuth,
        setAuthScreen,
        showReportPost,
        preferredLanguage,
        userProfile,
        showLanguageSelection,
        showDeletePost,
        showEditPost
    } = useOutletContext();

    const [searchParams] = useSearchParams();

    const [posts, setPosts] = React.useState(null);
    const [nextPage, setNextPage] = React.useState(1);

    const query = searchParams.get("query");

    React.useEffect(() => {
        if (query) {
            fetchData(1);
        }
    }, [query]);

    const fetchData = (page) => {
        const url = POSTS_URL + `/v1/posts?query=${query}&page=${page}`;

        axios.get(url).then((response) => {
            if (posts == null || page === 1) {
                setPosts(response.data.data.posts);
            } else {
                setPosts(posts.concat(response.data.data.posts));
            }
            setNextPage(response.data.data.page.nextPage);
        });
    }

    const deleteCallback = () => {
        setPosts(null);
        fetchData(1);
    }

    return (
        <>
            <Helmet>
                <title>Search</title>
                <meta name="description" content="Search"/>
                <meta property="og:title" content="Search"/>
                <meta property="og:description" content="Search"/>
            </Helmet>
            <Content posts={posts} nextPage={() => fetchData(nextPage)} pageNumber={nextPage}
                     showAuth={showAuth} setAuthScreen={setAuthScreen}
                     showReportPost={showReportPost} preferredLanguage={preferredLanguage} userProfile={userProfile}
                     deleteCallback={deleteCallback} showLanguageSelection={showLanguageSelection}
                     showDeletePost={showDeletePost} showEditPost={showEditPost}/>
        </>
    )
}

export default SearchResults