import React from 'react';
import Card from "../common/card";
import {Placeholder} from "react-bootstrap";

const CreatorProfilePlaceHolder = () => {

    return (
        <div className="w-75">
            <Placeholder as={Card.Text} animation="glow">
                <Placeholder xs={8}/>
            </Placeholder>
            <div></div>
            <Placeholder as={Card.Text} animation="glow">
                <Placeholder className="w-100"/>
            </Placeholder>
        </div>
    )
};

export default CreatorProfilePlaceHolder;