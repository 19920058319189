import React, {useState} from 'react'
import {Col, Row} from 'react-bootstrap'
import InfiniteScroll from "react-infinite-scroll-component";
import PostPlaceholder from "../../components/postup/post-placeholder";
import Post from "../../components/postup/post";
import axios from "axios";
import {useOutletContext} from "react-router-dom";
import {POSTS_URL} from "../../utilities/urls";

const UserPosts = () => {
    const {
        showDeletePost,
        preferredLanguage,
        userProfile,
        showLanguageSelection
    } = useOutletContext();

    const [myPosts, setMyPosts] = useState([]);
    const [myPostsNextPage, setMyPostsNextPage] = useState(1);

    React.useEffect(() => {
        if (userProfile && myPosts.length === 0) {
            fetchMyPosts(1);
        }
    }, []);

    const fetchMyPosts = (page) => {
        const url = POSTS_URL + `/v1/posts?creator=${userProfile.userId}&page=${page}`;

        axios.get(url).then((response) => {
            setMyPosts(myPosts.concat(response.data.data.posts));
            setMyPostsNextPage(response.data.data.page.nextPage);
        });
    }

    const callback = () => {
        const url = POSTS_URL + `/v1/posts?creator=${userProfile.userId}&page=1&size=${myPosts.length}`;

        axios.get(url).then((response) => {
            setMyPosts(response.data.data.posts);
            setMyPostsNextPage(response.data.data.page.nextPage);
        });
    }

    const showDeletePostWithCallback = (postId) => {
        showDeletePost(postId, callback);
    }

    return userProfile && (
        <Row>
            <Col lg={12} className="row m-0 p-0">
                <InfiniteScroll
                    dataLength={myPosts.length}
                    next={() => fetchMyPosts(myPostsNextPage)}
                    hasMore={myPostsNextPage != null}
                    loader={
                        <PostPlaceholder/>
                    }
                >
                    {
                        myPosts.map((post, index) => {
                            return (<Col sm={12} key={index}>
                                <Post post={post}
                                      showDeletePost={showDeletePostWithCallback}
                                      preferredLanguage={preferredLanguage}
                                      userProfile={userProfile}
                                      showLanguageSelection={showLanguageSelection}/>
                            </Col>)
                        })
                    }
                </InfiniteScroll>
            </Col>
        </Row>
    )
}

export default UserPosts;