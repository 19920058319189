import React from "react";
import axios from "axios";
import "./assets/scss/postup.scss"
import "./assets/scss/customizer.scss"
import {useDispatch, useSelector} from 'react-redux';
import {
    setDismissWelcome,
    setLanguages,
    setPreferredLanguage,
    setSpaceNames,
    setStates,
    setTopCreators,
    setUserProfile
} from "./store/app/reducers";
import {POSTS_URL, SPACES_URL, USER_URL} from "./utilities/urls";
import Joyride, {ACTIONS, STATUS} from "react-joyride";

const languagesURL = USER_URL + "/v1/languages";
const statesURL = USER_URL + "/v1/states";
const profileUrl = USER_URL + "/v1/user";
const topCreatorsURL = POSTS_URL + "/v1/posts/top/creators";
const spaceNamesURL = SPACES_URL + "/spaces";

function App(props) {
    const dispatch = useDispatch();

    const userProfile = useSelector(state => state.userProfile);

    const dismissWelcome = useSelector(state => state.dismissWelcome) || userProfile?.flags?.dismissWelcome;

    React.useEffect(() => {
        fetchSpaceNames();
        fetchLanguages();
        fetchStates();
        fetchTopCreators();
        getUserProfile();

        // const gsiClient = document.createElement('script');
        // gsiClient.src = 'https://accounts.google.com/gsi/client';
        // gsiClient.async = true;
        // document.head.appendChild(gsiClient);
        //
        // const fbSDK = document.createElement('script');
        // fbSDK.src = 'https://connect.facebook.net/en_US/sdk.js#xfbml=1&version=v20.0';
        // fbSDK.async = true;
        // fbSDK.crossorigin = "anonymous";
        // fbSDK.nonce = "qabI7LC1";
        // document.head.appendChild(fbSDK);
    }, []);

    const fetchSpaceNames = () => {
        axios.get(spaceNamesURL).then((response) => {
            dispatch(setSpaceNames(response.data));
        });
    }

    const fetchLanguages = () => {
        axios.get(languagesURL).then((response) => {
            dispatch(setLanguages(response.data.data));
        });
    }

    const fetchStates = () => {
        axios.get(statesURL).then((response) => {
            dispatch(setStates(response.data.data));
        });
    }

    const fetchTopCreators = () => {
        axios.get(topCreatorsURL).then((response) => {
            dispatch(setTopCreators(response.data.data));
        });
    }

    const getUserProfile = () => {
        axios.get(profileUrl).then((response) => {
            dispatch(setUserProfile(response.data.data));
            response.data.data.preferredLanguage && dispatch(setPreferredLanguage(response.data.data.preferredLanguage));
        }).catch(function (error) {
            dispatch(setUserProfile(null));
        });
    }

    window.googleSignIn = () => {
    }

    const steps = [
        {
            target: 'body',
            content: (<>
                <div>
                    <h5 className="welcome">Welcome to PostUp</h5>
                </div>
                <br/>
                <div>
                    At PostUp, we believe in redefining how people experience and interact on social media.
                </div>
                <br/>
                <div>
                    <span className="welcome-bold">Privacy First </span>
                    Unlike other platforms, we don’t track your activities or build user profiles for advertising.
                    Your data is your own, and we believe your social experience should be just that – yours alone.
                    <br/>
                    No more excessive data collection, intrusive algorithms, or feeding your data to AI.
                </div>
                <br/>
                <div>
                    <span className="welcome-bold">Seamless Language Translation </span>
                    Break down language barriers with our translation feature. Whether connecting with friends across
                    the
                    globe or engaging with diverse communities, our language translation makes it seamless.
                </div>
            </>),
            placement: "center"
        },
        /*{
            target: '#language-translation',
            content: (<>
                <div>
                    <h5 className="welcome">Choose Your Language</h5>
                </div>
                <br/>
                <div>
                    Select your preferred language to translate posts and enjoy content your way!
                </div>
            </>),
            disableScrolling: true
        },*/
        {
            target: '#like-post',
            content: (<>
                <div>
                    <h5 className="welcome">Liked the Post?</h5>
                </div>
                <br/>
                <div>
                    Click here to give it a thumbs up and share your appreciation!
                </div>
            </>),
            disableScrolling: true
        },
        {
            target: '#comments',
            content: (<>
                <div>
                    <h5 className="welcome">Join the Conversation</h5>
                </div>
                <br/>
                <div>
                    Click here to dive into the discussion and share your thoughts!
                </div>
            </>),
            disableScrolling: true
        },
        {
            target: '#translate-button',
            content: (<>
                <div>
                    <h5 className="welcome">Translate This Post!</h5>
                </div>
                <br/>
                <div>
                    Click here to view the post in your preferred language.
                </div>
            </>),
            disableScrolling: true
        },
        {
            target: '#share-button',
            content: (<>
                <div>
                    <h5 className="welcome">Spread the Word!</h5>
                </div>
                <br/>
                <div>
                    Click here to pass it along and let others see it too!
                </div>
            </>),
            disableScrolling: true
        }
    ];

    const handleJoyrideCallback = (data) => {
        const {action, index, origin, status, type} = data;
        if (action === ACTIONS.CLOSE || action === ACTIONS.SKIP) {
            dispatch(setDismissWelcome(true));
            document.body.style.overflow = "unset";
        } else if (status === STATUS.FINISHED || status === STATUS.SKIPPED) {
            dispatch(setDismissWelcome(true));
            document.body.style.overflow = "unset";
        } else {
            if (index === 0) {
                if (action === ACTIONS.CLOSE || action === ACTIONS.RESET) {
                    document.body.style.overflow = "unset";
                } else {
                    document.body.style.overflow = "hidden"
                }
            } else {
                document.body.style.overflow = "unset"
            }
        }
    };

    return (
        <>
            {
                !dismissWelcome &&
                <Joyride continuous
                         run={true}
                         showSkipButton
                         steps={steps}
                         disableOverlayClose
                         callback={handleJoyrideCallback}
                         locale={
                             {
                                 last: "Close"
                             }
                         }
                         styles={
                             {
                                 buttonNext: {
                                     backgroundColor: "#1877f2",
                                     borderRadius: 4,
                                     color: '#fff',
                                 },
                                 buttonBack: {
                                     color: "#1877f2"
                                 },
                                 options: {
                                     width: "425px"
                                 },
                                 tooltip: {
                                     fontSize: "14px"
                                 }
                             }
                         }/>
            }
            <div
                id='g_id_onload'
                data-client_id='187512437830-6j3icgc3l6sdaik10at9ci1t951u7b3f.apps.googleusercontent.com'
                data-context="signin"
                data-ux_mode="popup"
                data-auto_prompt="false"
                data-callback="googleSignIn"
            ></div>
            <div className="App">
                {props.children}
            </div>
        </>
    );
}

export default App;
