import React from "react";
import Top from "../views/posts/top";
import UserProfile from "../views/user/user-profile";
import PrivacyPolicy from "../views/extrapages/privacy-policy";
import TermsOfService from "../views/extrapages/terms-of-service";
import Error404 from "../views/errors/error404";
import Error500 from "../views/errors/error500";
import Maintenance from "../views/extrapages/maintenance";
import PostsContainer from "../views/posts/posts-container";
import Home from "../views/posts/home";
import Category from "../views/posts/category";
import PostWithComments from "../views/post/post-with-comments";
import Creator from "../views/user/creator";
import DataDeletionRequest from "../views/user/data-deletion-request";
import UserSettings from "../views/user/user-settings";
import UserLikes from "../views/user/user-likes";
import UserPosts from "../views/user/user-posts";
import UserFollowing from "../views/user/user-following";
import Tag from "../views/posts/tag";
import Error from "../views/errors/error";
import SearchResults from "../views/posts/search-results";
import Spaces from "../views/spaces/spaces";

export const IndexRouters = [
    {
        path: "/",
        element: <PostsContainer/>,
        errorElement: <Error/>,
        children: [
            {
                path: "/",
                element: <Home/>,
            },
            {
                path: "/top",
                element: <Top/>,
            },
            {
                path: "/spaces",
                element: <Spaces/>,
            },
            {
                path: "/category/:id",
                element: <Category/>,
            },
            {
                path: "/tag/:id",
                element: <Tag/>,
            },
            {
                path: "/post/:id",
                element: <PostWithComments/>,
            },
            {
                path: "/search",
                element: <SearchResults/>,
            },
            {
                path: "/user",
                element: <UserProfile/>,
                children: [
                    {
                        path: "/user",
                        element: <UserPosts/>,
                    },
                    {
                        path: "/user/likes",
                        element: <UserLikes/>,
                    },
                    {
                        path: "/user/following",
                        element: <UserFollowing/>,
                    }
                ]
            },
            {
                path: "/user/settings",
                element: <UserSettings/>,
            },
            {
                path: "/creator/:id",
                element: <Creator/>,
            }
        ],
    },
    {
        path: "/privacy-policy",
        element: <PrivacyPolicy/>,
    },
    {
        path: "/terms",
        element: <TermsOfService/>,
    },
    {
        path: "/error/400",
        element: <Error404/>,
    },
    {
        path: "/error/500",
        element: <Error500/>,
    },
    {
        path: "/maintenance",
        element: <Maintenance/>,
    },
    {
        path: "/data/deletion/facebook",
        element: <DataDeletionRequest/>,
    },
    {
        path: "*",
        element: <Error404/>,
    },
];
