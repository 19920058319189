import React from 'react'
import Content from "./content";
import {useOutletContext} from "react-router-dom";
import axios from "axios";
import {POSTS_URL} from "../../utilities/urls";

const Home = () => {
    const {
        homePosts,
        setHomePosts,
        homePage,
        setHomePage,
        showAuth,
        setAuthScreen,
        showReportPost,
        preferredLanguage,
        userProfile,
        showLanguageSelection,
        showDeletePost,
        showEditPost
    } = useOutletContext();

    React.useEffect(() => {
        if (homePosts === null) {
            fetchData(1);
        }
    }, [homePosts]);

    const fetchData = (page) => {
        const url = POSTS_URL + `/v1/posts?type=home&page=${page}`;

        axios.get(url).then((response) => {
            if (homePosts == null || page === 1) {
                setHomePosts(response.data.data.posts);
            } else {
                setHomePosts(homePosts.concat(response.data.data.posts));
            }
            setHomePage(response.data.data.page.nextPage);
        });
    }

    const deleteCallback = () => {
        setHomePosts(null);
        fetchData(1);
    }

    return (
        <Content posts={homePosts} nextPage={() => fetchData(homePage)} pageNumber={homePage}
                 showAuth={showAuth} setAuthScreen={setAuthScreen}
                 showReportPost={showReportPost} deleteCallback={deleteCallback}
                 preferredLanguage={preferredLanguage} userProfile={userProfile} showDeletePost={showDeletePost}
                 showLanguageSelection={showLanguageSelection} showEditPost={showEditPost}/>
    )
}

export default Home