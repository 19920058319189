import React, {useState} from 'react';
import Card from "../common/card";
import {Button, Form, Spinner} from "react-bootstrap";
import {POST_URL} from "../../utilities/urls";
import axios from "axios";

const Suggestions = ({post, reloadPost}) => {
    const [selectAll, setSelectAll] = React.useState(false);
    const [showSpinner, setShowSpinner] = useState(false);
    const [selectedTags, setSelectedTags] = React.useState(post.suggestedTags.reduce((acc, current) => {
        acc[current] = false;
        return acc;
    }, {}));

    const selectAllToggle = () => {
        const tags = {};
        for (let i = 0; i < post.suggestedTags.length; i++) {
            tags[post.suggestedTags[i]] = !selectAll;
        }
        setSelectedTags(tags);

        setSelectAll(!selectAll);
    }

    const onCheckboxChange = (e) => {
        setSelectedTags({...selectedTags, [e.target.name]: e.target.checked});
    }

    const onFormSubmit = e => {
        e.preventDefault();

        const formData = new FormData(e.target);

        const selectedTags = [];
        for (let i = 0; i < post.suggestedTags.length; i++) {
            const checked = formData.get(post.suggestedTags[i]);
            console.log(formData.get(post.suggestedTags[i]));

            if (checked === "on") {
                selectedTags.push(post.suggestedTags[i]);
            }
        }

        console.log(selectedTags);

        if (selectedTags.length > 0) {
            setShowSpinner(true);

            const options = {
                method: 'PATCH',
                url: POST_URL + `/v1/post`,
                data: {
                    postId: post.postId,
                    mediaId: post.mediaId,
                    pollOptions: post.pollOptions,
                    content: post.content,
                    tags: post.tags ? post.tags.concat(selectedTags) : selectedTags,
                    dismissSuggestedTags: true
                }
            };

            axios.request(options).then(function (response) {
                setShowSpinner(false);
                reloadPost();
            }).catch(function (error) {
                setShowSpinner(false);
                console.error(error);
            });
        }
    }

    const dismissSuggestion = () => {
        const options = {
            method: 'PATCH',
            url: POST_URL + `/v1/post`,
            data: {
                postId: post.postId,
                mediaId: post.mediaId,
                pollOptions: post.pollOptions,
                content: post.content,
                tags: post.tags,
                dismissSuggestedTags: true
            }
        };

        axios.request(options).then(function (response) {

        }).catch(function (error) {
            console.error(error);
        });
    }

    return (
        <Card className="card-block card-stretch card-height">
            <Card.Body>
                <h3>Smart Hashtags</h3>
                <Form onSubmit={onFormSubmit} onClick={(e) => e.stopPropagation()}>
                    <div className="d-flex">
                        {
                            post.suggestedTags.map(tag =>
                                <Form.Check className="d-inline-block m-2 pt-1" key={tag}>
                                    <Form.Check.Input type="checkbox" name={tag} checked={selectedTags[tag]}
                                                      onChange={onCheckboxChange}/>
                                    <Form.Check.Label>{tag}</Form.Check.Label>
                                </Form.Check>)
                        }
                    </div>
                    <div className="d-flex align-items-center justify-content-between w-100 mt-4">
                        <Button variant="link" size="sm"
                                onClick={selectAllToggle}>{selectAll ? "Select None" : "Select All"}</Button>
                        <div>
                            <Button variant="secondary" size="sm" onClick={dismissSuggestion}>Close</Button>
                            {
                                showSpinner ?
                                    <Spinner className="float-end suggestions-spinner"/> :
                                    <Button variant="primary" size="sm" type="submit" className="mx-2">Save</Button>
                            }
                        </div>
                    </div>
                </Form>
            </Card.Body>
        </Card>
    )
};

export default Suggestions;