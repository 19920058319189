import React, {useRef, useState} from 'react';
import Card from "../common/card";
import {Badge, Dropdown} from "react-bootstrap";
import Share from "../common/share";
import {Link} from "react-router-dom";
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {faEllipsis, faLanguage} from '@fortawesome/free-solid-svg-icons'
import {faComments, faHeart} from '@fortawesome/free-regular-svg-icons'
import ReactPlayer from 'react-player'
import VisibilitySensor from 'react-visibility-sensor';
import axios from "axios";
import {POSTS_URL} from "../../utilities/urls";
import Poll from "./poll";
import {updateLikes} from "../../store/app/reducers";
import {useDispatch} from "react-redux";
import {defaultAvatar, getAge, verifyUserProfile} from "../../utilities/util";

const Post = ({
                  post,
                  showAuth,
                  setAuthScreen,
                  showDeletePost,
                  showReportPost,
                  preferredLanguage,
                  userProfile,
                  showLanguageSelection,
                  showEditPost
              }) => {
    const imageRef = useRef();

    const dispatch = useDispatch();

    const [visibility, setVisibility] = useState(false);
    const [likes, setLikes] = useState(post?.likes);
    const [translatedContent, setTranslatedContent] = useState(null);
    const [translatedImage, setTranslatedImage] = useState(null);

    const handleLike = () => {
        verifyUserProfile(userProfile, showAuth, setAuthScreen, likePost);
    }

    const likePost = () => {
        if (!(userProfile?.likes && userProfile.likes[`${post.postId}`])) {
            const options = {
                method: 'PATCH',
                url: POSTS_URL + `/v1/posts/${post.postId}/like`
            };

            axios.request(options).then(function (response) {
                setLikes(likes + 1);
                dispatch(updateLikes({postId: post.postId, like: 1}));
            }).catch(function (error) {
                console.error(error);
            });
        } else {
            const options = {
                method: 'PATCH',
                url: POSTS_URL + `/v1/posts/${post.postId}/like?undo=true`
            };

            axios.request(options).then(function (response) {
                setLikes(likes - 1);
                dispatch(updateLikes({postId: post.postId, like: null}));
            }).catch(function (error) {
                console.error(error);
            });
        }
    }

    const handleReport = () => {
        verifyUserProfile(userProfile, showAuth, setAuthScreen, () => showReportPost(post.postId));
    }

    const handleTranslation = () => {
        if (preferredLanguage == null) {
            showLanguageSelection(translate);
        } else {
            translate(preferredLanguage);
        }
    }

    const translate = (preferredLanguage) => {
        if (translatedContent || translatedImage) {
            setTranslatedContent(null);
            setTranslatedImage(null);
        } else if (post.translatedContents || post.media?.translatedMedia) {
            const translated = post.translatedContents?.filter(d => d.languageCode === preferredLanguage);
            if (translated != null && translated.length > 0) {
                setTranslatedContent(translated[0].content);
            }

            const translatedMedia = post.media?.translatedMedia?.filter(d => d.languageCode === preferredLanguage);
            if (translatedMedia != null && translatedMedia.length > 0) {
                setTranslatedImage(translatedMedia[0].media.url);
            }
        }
    }

    return (
        post &&
        <Card className="card-block card-stretch card-height" id={post.postId}>
            <Card.Body>
                <div className="user-post-data">
                    <div className="d-flex justify-content-between">
                        <div className="d-flex">
                            <Link to={`/creator/${post.createdBy}`} className="me-2">
                                <img className="avatar rounded-circle img-fluid"
                                     src={post.creator.avatarUrl || defaultAvatar} alt=""/>
                            </Link>
                            <div className="mb-0 ms-1 d-flex">
                                <Link to={`/creator/${post.createdBy}`} className="d-flex mt-1">
                                    <div className="mb-0 d-inline-block" style={{fontWeight: 600}}>
                                        {post.creator.displayName}
                                    </div>
                                </Link>
                                <div className="mt-1">
                                    &nbsp;&#8226; {getAge(post.createdDate)}
                                </div>
                            </div>
                        </div>
                        <div className="card-post-toolbar">
                            <Dropdown>
                                <Dropdown.Toggle variant="bg-transparent" style={{color: 'white'}}>
                                    <FontAwesomeIcon icon={faEllipsis} style={{fontSize: 1.1 + "rem"}}/>
                                </Dropdown.Toggle>
                                <Dropdown.Menu className="dropdown-menu m-0 p-0">
                                    {
                                        (userProfile && userProfile.userId === post.createdBy) ?
                                            <>
                                                <Dropdown.Item className="p-3" to="#"
                                                               onClick={() => showEditPost(post.postId)}>
                                                    <div className="data ms-2">
                                                        Edit
                                                    </div>
                                                </Dropdown.Item>
                                                <Dropdown.Item className="p-3" to="#"
                                                               onClick={() => showDeletePost(post.postId)}>
                                                    <div className="data ms-2">
                                                        Delete
                                                    </div>
                                                </Dropdown.Item>
                                            </> :
                                            <Dropdown.Item className="p-3" to="#" onClick={handleReport}>
                                                <div className="ms-2">
                                                    Report
                                                </div>
                                            </Dropdown.Item>
                                    }
                                </Dropdown.Menu>
                            </Dropdown>
                        </div>
                    </div>
                </div>

                <Link to={`/post/${post.postId}`} style={{fontSize: "16px", fontWeight: 500}}>
                    <div className="mb-1">{translatedContent ? translatedContent : post.content}</div>
                </Link>
                {
                    post.media &&
                    <div className="post-image">
                        {post.media.type === "image" ?
                            <Link to={`/post/${post.postId}`}>
                                <img src={translatedImage ? translatedImage : post.media.url} alt={post.postId}
                                     className="img-fluid w-100 image"
                                     ref={imageRef}/>
                            </Link> :
                            (<VisibilitySensor
                                onChange={(isVisible) => {
                                    setVisibility(isVisible)
                                }}
                            >
                                <ReactPlayer url={post.media.url} controls playing={visibility}
                                             muted={true} width="100%"/>
                            </VisibilitySensor>)}

                    </div>
                }
                {
                    post.type === "poll" && <Poll post={post} userProfile={userProfile} showAuth={showAuth}
                                                  setAuthScreen={setAuthScreen}/>
                }
                {
                    post.tags &&
                    <div className="mt-2 ms-3">
                        {post.tags.map(tag =>
                            (<Badge key={tag} pill bg="primary" className="ms-1"
                                    style={{fontSize: 0.8 + "rem"}}>
                                <Link to={`/tag/${tag}`} className="tag-link">
                                    {tag}
                                </Link>
                            </Badge>)
                        )}
                    </div>
                }

                <div className="d-flex justify-content-between align-items-center flex-wrap comment-area mt-2">
                    <div className="like-block position-relative d-flex align-items-center">
                        {likes && likes > 0 ? <div className="me-2">{likes}</div> : <></>}
                        <Link to="#" onClick={() => handleLike()}
                              className={"feather-icon d-flex align-items-center " + (userProfile?.likes && userProfile.likes[`${post.postId}`] ? " liked" : "")}>
                            <FontAwesomeIcon id="like-post" icon={faHeart} style={{fontSize: 1.4 + "rem"}}
                                             className="me-1"/>
                        </Link>

                        <div className="d-flex align-items-center ms-5">
                            {post.commentsCount && post.commentsCount > 0 ?
                                <div className="me-2">{post.commentsCount}</div> : <></>}
                            <Link to={`/post/${post.postId}?view=comments`} className="feather-icon">
                                <FontAwesomeIcon id="comments" icon={faComments} style={{fontSize: 1.3 + "rem"}}/>
                            </Link>
                        </div>

                    </div>
                    <div className="d-flex align-items-center">
                        <div className="feather-icon me-4 mt-1">
                            {
                                (post.translatedContents || post.media?.translatedMedia) &&
                                <Link to="#" className="d-flex align-items-center mb-1" id="translate-button"
                                      onClick={handleTranslation}>
                                    <FontAwesomeIcon icon={faLanguage} style={{fontSize: 1.4 + "rem"}}/>
                                </Link>
                            }
                        </div>
                        <Share imageRef={imageRef}
                               title={translatedContent ? translatedContent : post.content}
                               url={post.url}/>
                    </div>
                </div>
            </Card.Body>
        </Card>
    )
}

export default Post;