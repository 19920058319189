import {useEffect, useRef, useState} from 'react';
import {io} from 'socket.io-client';
import {SOCKETS_URL, SPACES_URL} from "../../utilities/urls";
import axios from "axios";
import {toast} from "react-toastify";
import {SOMETHING_WENT_WRONG, UNABLE_TO_CONNECT} from "../../utilities/util";

const ClientSocket = ({accessToken, space, userProfile}) => {
    const socketRef = useRef();
    const [messages, setMessages] = useState([]);
    const [disableInput, setDisableInput] = useState(false);

    useEffect(() => {
        if (space) {
            fetchMessages();

            socketRef.current = io(SOCKETS_URL, {
                extraHeaders: {
                    "Authorization": accessToken
                },
                withCredentials: true,
                reconnectionAttempts: 10
            });

            socketRef.current.emit('join', space, (success, error) => {
                if (!success && error) {
                    console.log(error);
                    toast.error(error, {
                        position: "bottom-right",
                        autoClose: 2000,
                        closeOnClick: true,
                        pauseOnHover: true
                    });
                }
            });

            socketRef.current.on('message', message => {
                setMessages(messages => [...messages, message]);
            });

            socketRef.current.on('reload', message => {
                fetchMessages();
            });

            socketRef.current.on("error", (error) => {
                console.log(error);
                setDisableInput(true);
            });

            socketRef.current.on("reconnect", () => {
                setDisableInput(false);
            });

            socketRef.current.on("reconnect_attempt", () => {
                setDisableInput(true);
            });

            socketRef.current.on("reconnect_failed", () => {
                setDisableInput(true);

                toast.error(UNABLE_TO_CONNECT, {
                    position: "bottom-right",
                    autoClose: 2000,
                    closeOnClick: true,
                    pauseOnHover: true
                });
            });

            return () => {
                socketRef.current.emit('leave');
                socketRef.current.disconnect();
            };
        }
    }, [socketRef, space]);

    const fetchMessages = () => {
        axios.get(`${SPACES_URL}/${space}/messages`).then((response) => {
            setMessages(response.data);
        }).catch((error) => {
            toast.error(SOMETHING_WENT_WRONG, {
                position: "bottom-right",
                autoClose: 2000,
                closeOnClick: true,
                pauseOnHover: true
            });
        });
    }

    const sendMessage = (message, callback) => {
        if (space && userProfile) {
            socketRef.current.emit('sendMessage', {space, message}, (success, error) => {
                if (!success && error) {
                    console.log(error);
                    toast.error(error, {
                        position: "bottom-right",
                        autoClose: 2000,
                        closeOnClick: true,
                        pauseOnHover: true
                    });
                }
            });
            callback && callback();
        }
    };

    return {messages, sendMessage, disableInput};
};

export default ClientSocket;